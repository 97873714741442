<template>
  <!----------------- CREATOR ----------------->
  <form @submit.prevent="submit" class="form-submit">
    <div class="inputs">
      <div class="input-field">
        <input maxlength="100" v-model="form.workshopTitle" type="text" class="form-control"
               id="txt_title" />
        <label class="form-control inputlabel" for="txt_title">
          {{ $t("WorkshopFormComponent.title") }}
        </label>
      </div>
      <div class="input-field">
        <input
          v-model="form.description"
          type="text"
          class="form-control"
          name="subject"
          id="txt_description"
        />
        <label class="form-control inputlabel" for="txt_description">
          {{ $t("WorkshopFormComponent.description") }}
        </label>
      </div>
      <label class="form-control external-label">
          {{ $t("WorkshopFormComponent.beginDate") }}
      </label>
      <date-time-selection-component v-model="form.dateWorkshopBegin"/>
      <label class="form-control external-label">
          {{ $t("WorkshopFormComponent.endDate") }}
      </label>
      <date-time-selection-component v-model="form.dateWorkshopEnd"/>
      <!----------------- ADD PROJECT NAME ----------------->
      <div class="select-container">
        <div
          class="participants-container"
          ref="projectName-container"
          @click.prevent="toggleProjectName"
        >
          <div>
            <span class="circle-plus">
              <font-awesome-icon icon="fa-circle-plus" />
            </span>
            <span class="btn-text">{{ $t("WorkshopFormComponent.addProjectName") }}</span>
          </div>
          <div v-if="!showProjectName" class="pill-container">
            <div class="pill-element" v-if="form.projectName">
              {{ form.projectName }}
            </div>
          </div>
        </div>
        <project-name-selection-component
          :projectNames="projectNames"
          v-if="showProjectName"
          v-model="form.projectName"
        />
      </div>
      <!----------------- ADD PARTICIPANTS ------------------>
      <div class="select-container">
        <!-- eslint-disable-next-line max-len -->
        <div
          class="participants-container"
          ref="participants-container"
          @click.prevent="toggleParticipants"
        >
          <div>
            <span class="circle-plus">
              <font-awesome-icon icon="fa-circle-plus" />
            </span>
            <span class="btn-text">{{ $t("WorkshopFormComponent.addParticipants") }}</span>
          </div>
          <div v-if="!showParticipants" class="pill-container">
            <div
              v-for="selectedParticipant in form.participants"
              :key="selectedParticipant"
              class="pill-element"
            >
              {{ selectedParticipant.name }}
            </div>
          </div>
        </div>
        <participants-selection-component
          :participants="participants"
          v-model="form.participants"
          v-if="showParticipants"
        />
      </div>

      <!----------------- ADD TAGS ----------------->
      <div class="select-container">
        <select v-model="form.tags">
          <option disabled hidden value="">
            {{ $t("WorkshopFormComponent.tagDropdown") }}</option>
          <option v-for="tag in workshopTags" :key="tag.key">
            {{ tag.value }}
          </option>
        </select>
      </div>
      <!----------------- ADD FACILITATOR ----------------->
      <div class="select-container">
        <div
          class="participants-container"
          ref="facilitator-container"
          @click.prevent="toggleFacilitator"
        >
          <div>
            <span class="circle-plus">
              <font-awesome-icon icon="fa-circle-plus" />
            </span>
            <span class="btn-text">{{ $t("WorkshopFormComponent.addFacilitator") }}</span>
          </div>
          <div v-if="!showFacilitator" class="pill-container">
            <div class="pill-element" v-if="form.workshopFacilitator._id">
              {{ form.workshopFacilitator.name }}
            </div>
          </div>
        </div>
        <facilitator-selection-component
          :facilitators="participants"
          v-if="showFacilitator"
          v-model="form.workshopFacilitator"
        />
      </div>
    </div>
    <div class="btns">
      <button v-if="!isEdit" type="submit" class="btn-create" @click.prevent="submit">
        {{ $t("WorkshopFormComponent.create") }}
      </button>
      <button v-else type="submit" class="btn-create" @click.prevent="submit">
        {{ $t("WorkshopFormComponent.edit") }}
      </button>
    </div>
  </form>
</template>

<script>
import NetworkContent from '@/helpers/networking/NetworkContent';
import ParticipantsSelectionComponent from './ParticipantsSelectionComponent.vue';
import FacilitatorSelectionComponent from './FacilitatorSelectionComponent.vue';
import ProjectNameSelectionComponent from './ProjectNameSelectionComponent.vue';
import DateTimeSelectionComponent from './DateTimeSelectionComponent.vue';

export default {
  name: 'WorkshopFormComponent',
  props: {
    value: Object,
  },
  emits: ['submit'],
  components: {
    ParticipantsSelectionComponent,
    FacilitatorSelectionComponent,
    ProjectNameSelectionComponent,
    DateTimeSelectionComponent,
  },
  data() {
    return {
      form: {
        tags: '',
        participants: [],
        workshopFacilitator: {},
        dateWorkshopBegin: new Date(),
        dateWorkshopEnd: new Date(),
        ...this.value,
      },
      showParticipants: false,
      showTags: false,
      showFacilitator: false,
      showProjectName: false,
      projectNames: [],
      participants: [],
      objForTags: this.$store.getters.getWorkshopTags,
    };
  },
  computed: {
    isEdit() {
      return !!this.value?._id;
    },
    workshopTags() {
      const workshopTags = [];
      Object.entries(this.objForTags).map((ele) => {
        workshopTags.push({ key: ele[0], value: ele[1] });
        return ele;
      });
      return workshopTags;
    },
  },
  methods: {
    submit() {
      if (this.checkForErrors()) {
        return;
      }
      const params = {
        workshopTitle: this.form.workshopTitle,
        tags: this.form.tags,
        description: this.form.description,
        projectName: this.form.projectName,
        participants: this.form.participants.map((s) => s._id),
        workshopFacilitatorID: this.form.workshopFacilitator._id,
      };
      params.dateWorkshopBegin = new Date(this.form.dateWorkshopBegin).toUTCString();
      params.dateWorkshopEnd = new Date(this.form.dateWorkshopEnd).toUTCString();
      if (this.form._id) {
        params._id = this.form._id;
      }
      this.$emit('submit', params);
    },
    checkForErrors() {
      let hasError = false;
      const beginDate = new Date(this.form.dateWorkshopBegin);
      const endDate = new Date(this.form.dateWorkshopEnd);
      if (beginDate >= endDate) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('WorkshopFormComponent.theBeginDateMustBeEarlier'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.form.workshopTitle) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('WorkshopFormComponent.titleRequired'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.form.participants.length === 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('WorkshopFormComponent.selectParticipantsErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.form.tags === '') {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('WorkshopFormComponent.selectTag'),
          type: 'error',
        });
        hasError = true;
      }

      return hasError;
    },
    debounce(func, delay) {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    async getParticipants() {
      const { data } = await NetworkContent.getParticipants();
      const participants = data.users ?? [];
      this.participants = participants.map((s) => ({
        ...s,
        _id: s.id,
      }));
    },
    async getProjectNames() {
      const { data } = await NetworkContent.getProjectNames();
      this.projectNames = data.content ?? [];
    },
    toggleParticipants() {
      this.showParticipants = !this.showParticipants;
    },
    toggleTags() {
      this.showTags = !this.showTags;
    },
    toggleFacilitator() {
      this.showFacilitator = !this.showFacilitator;
    },
    toggleProjectName() {
      this.showProjectName = !this.showProjectName;
    },
    adjustParticipantContainerHeight() {
      const participantContainer = this.$refs['participants-container'];
      const resize = () => {
        if (participantContainer.clientHeight >= 60) {
          participantContainer.style.flexDirection = 'column';
          participantContainer.style.alignItems = 'flex-start';
        } else {
          participantContainer.style.flexDirection = 'row';
          participantContainer.style.alignItems = 'center';
        }
      };
      resize();
      const resizeHandler = this.debounce(() => resize(), 100);
      const resizeObserver = new ResizeObserver(resizeHandler);

      resizeObserver.observe(participantContainer);
    },
    adjustTagsContainerHeight() {
      const tagContainer = this.$refs['tags-container'];
      const resize = () => {
        if (tagContainer.clientHeight >= 60) {
          tagContainer.style.flexDirection = 'column';
          tagContainer.style.alignItems = 'flex-start';
        } else {
          tagContainer.style.flexDirection = 'row';
          tagContainer.style.alignItems = 'center';
        }
      };
      resize();
      const resizeHandler = this.debounce(() => resize(), 100);
      const resizeObserver = new ResizeObserver(resizeHandler);

      resizeObserver.observe(tagContainer);
    },
    async getWorkshopTags() {
      await this.$store.dispatch('getWorkshopTags');
    },
  },
  mounted() {
    this.getParticipants();
    this.getProjectNames();
    this.adjustParticipantContainerHeight();
    this.getWorkshopTags();
    // this.adjustTagsContainerHeight();
  },
};
</script>

<style scoped>
.form-submit {
  background-color: white;
  padding: 15px;
}
/*---------------- CONTENT NEW: CREATOR ------------------*/
.inputs {
  margin-top: 1.5rem;
}

input[type="text"],
input[type="datetime-local"],
select {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  font-size: 15px;
}

.input-field {
  transition: all 0.3s;
  height: 3.5rem;
}

.inputs .inputlabel {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  opacity: 1;
  transform: translateY(-2.3rem);
  transform-origin: 0 0;
  transition: all 0.3s;
  padding-left: var(--card-padding);
}

.external-label {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
}

.form-control::placeholder {
  color: transparent;
}

.form-control {
  transition: all 0.5s;
}

.form-control:focus {
  border-color: var(--color-orange);
}

.form-control:focus + label,
.form-control:not(:placeholder-shown) + label {
  transform: translateY(-3rem) scale(0.8); /*formatiert Label nach links oben und reduziert Größe*/
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
}

/*------------------ ADD PARTICIPANTS -------------------*/
.select-container {
  position: relative;
  width: 100%;
  margin-top: 8px;
}

.teams-container {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  min-height: 40px;
  padding: 2px;
}

.teams-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.teams-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin: 10px;
}

.teams-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.teams-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.teams-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.participants-container {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  min-height: 40px;
  padding: 2px;
}

.participants-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.participants-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin: 10px;
}

.participants-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.participants-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.participants-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.email-container {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 50px;
  font-size: 15px;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  min-height: 40px;
  padding: 2px;
}

.email-container .circle-plus {
  color: var(--color-orange);
  font-size: 20px;
  margin-left: 10px;
}

.email-container .btn-text {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  margin: 10px;
}

.email-container.open ~ .list-items {
  display: block;
  max-height: 240px;
  overflow-y: scroll;
}

.email-container.open ~ .list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.email-container.open ~ .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: none;
  overflow: hidden;
  z-index: 10;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 21px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

/*.item.checked .check-icon{
    transform: scale(1);
}*/

.pill-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  margin: 2rem 0;
  box-sizing: border-box;
}

.btn-create {
  font-family: Poppins, sans-serif;
  margin-right: 10px;
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-create:hover {
  transition: 0.5s;
  background-color: var(--color-fulvous);
}

@media (max-width: 780px) {
  .participants-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
