<template>
  <div class="popup-container" ref="ref_newUserDemoContainer">
    <button class="close-btn" @click.prevent="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
    <div class="slide-container">
      <div class="demo-text" v-if="getNewUserDemoContent">
        <p v-html="getNewUserDemoContent[slide].text[lang]"></p>
      </div>
      <div :class="{
          'slide-image-container': true,
          'has-demo-description': getNewUserDemoContent && getNewUserDemoContent[slide]?.description
        }">
        <img :src="mobileView ? getMobileImage : getImage"
             alt="picture" width="400" height="300"
             :class="{'image-box-shadow' : addBoxShadow}"
        />
        <button id="previous-button"
                class="next-previous-buttons"
                @click.prevent="slideBack">
          <font-awesome-icon
            :class="{'disabled': isLeftDisabled}"
            class="icon"
            icon="fa-chevron-up"
          />
        </button>
        <button
          id="next-button" class="next-previous-buttons"
          @click.prevent="slideForward">
          <font-awesome-icon
            :class="{'disabled': isRightDisabled}"
            class="icon"
            icon="fa-chevron-up"
          />
        </button>
      </div>
      <div class="demo-description" v-if="getNewUserDemoContent
        && getNewUserDemoContent[slide]?.description">
        <p v-html="getNewUserDemoContent[slide].description[lang]"></p>
      </div>
      <button v-if="slide !== getNewUserDemoContent.length - 1"
              @click.prevent="closePopup" id="skip-button">{{$t('NewUserDemo.skipDemo')}}</button>
      <button @click.prevent="closePopup" id="skip-button"
              v-else>{{$t('NewUserDemo.letsGo')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewUserDemoOverlayComponent',
  props: {
    overlay: {
      type: Object,
      default: null,
    },
    data: Object,
  },
  data() {
    return {
      loading: true,
      slide: 0,
      mobileView: false,
    };
  },
  mounted() {
    this.toggleLoading();
    this.checkForMobileView();
  },
  computed: {
    images() {
      return this.getNewUserDemoContent.map((s) => {
        const img = new Image();
        img.src = s.imageLink[this.lang];
        return img.src;
      });
    },
    mobileimages() {
      return this.getNewUserDemoContent.map((s) => {
        const img = new Image();
        img.src = s.imageLinkMobile[this.lang];
        return img.src;
      });
    },
    getNewUserDemoContent() {
      return this.$store.getters.getNewUserDemoContent;
    },
    getMobileImage() {
      const imageLink = this.mobileimages[this.slide];
      if (!imageLink || imageLink === '') {
        // eslint-disable-next-line global-require
        return require('@/assets/smiley-profil-bild.png');
      }
      return imageLink;
    },
    getImage() {
      const imageLink = this.images[this.slide];
      if (!imageLink || imageLink === '') {
        // eslint-disable-next-line global-require
        return require('@/assets/smiley-profil-bild.png');
      }
      return imageLink;
    },
    isRightDisabled() {
      return (this.slide === this.getNewUserDemoContent.length - 1);
    },
    isLeftDisabled() {
      return (this.slide === 0);
    },
    lang() {
      return this.$i18n.locale;
    },
    addBoxShadow() {
      return (this.slide !== 0 && this.slide !== this.getNewUserDemoContent.length - 1);
    },
  },
  methods: {
    debounce(func, delay) {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    async closePopup() {
      try {
        await this.$store.dispatch('newUserDemoComplete', {
          id: this.overlay._id,
        });
      } catch (error) {
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    toggleLoading() {
      this.loading = false;
    },
    slideBack() {
      if (this.slide === 0) {
        return;
      }
      this.slide -= 1;
    },
    slideForward() {
      if (this.slide === this.getNewUserDemoContent.length - 1) {
        return;
      }
      this.slide += 1;
    },
    checkForMobileView() {
      const container = this.$refs.ref_newUserDemoContainer;
      const resize = () => {
        this.mobileView = container.clientWidth <= 387;
      };
      resize();
      const resizeHandler = this.debounce(resize, 100);
      const resizeObserver = new ResizeObserver(resizeHandler);
      resizeObserver.observe(container);
    },
  },
};
</script>

<style scoped>
.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 1em 2em;
  border-radius: var(--card-border-radius);
  line-height: 1.5em;
  box-shadow: var(--card-box-shadow);
  width: 90vw;
  max-width: 800px;
  min-height: 650px;
  max-height: 550px;
  height: 650px;
  position: relative;
  overflow: hidden;
}

span {
  white-space: nowrap;
}
button {
  cursor: pointer;
}
.close-btn {
  font-family: 'Poppins';
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: var(--card-close-btn);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.close-btn:hover {
  color: var(--color-orange);
}

.next-previous-buttons {
  position: absolute;
  font-size: 40px;
  background-color: transparent;
}

.next-previous-buttons .icon:hover {
  color: var(--color-orange)
}

.next-previous-buttons.disabled.icon:hover {
  color: var(--color-disabled);
}

#next-button {
  top: 40%;
  right: -10px;
  rotate: 90deg;
}

#previous-button {
  top: 40%;
  left: -10px;
  rotate: -90deg;
}

.disabled {
  cursor: not-allowed;
  color: var(--color-disabled);
}

.slide-container {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.demo-text {
  width: 90%;
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
  height: 110px;
}

.demo-description {
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
  height: 90px;
}

.slide-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}

.slide-container img {
  aspect-ratio: auto;
  width: 90%;
  height: auto;
  border-radius: 5px;
  image-rendering: high-quality;
  margin-bottom: 20px;
}

.image-box-shadow {
  box-shadow: var(--card-box-shadow);
}

.icon.disabled:hover {
  color: var(--color-disabled);
  cursor: pointer;
}

#skip-button {
  position: absolute;
  right: 3rem;
  bottom: 2rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .popup-container {
    min-height: 590px;
    max-height: 590px;
    height: 590px;
  }
  #skip-button {
    left: 30px;
    right: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  #next-button {
    top: 40%;
    right: -10px;
    rotate: 90deg;
  }
  .next-previous-buttons {
    font-size: 35px;
  }

  #previous-button {
    top: 40%;
    left: -10px;
    rotate: -90deg;
  }
  .slide-container img {
    width: 88%;
  }

  .slide-image-container {
    max-height: 300px;
    height: 300px;
    min-height: 300px;
  }

  .slide-image-container.has-demo-description {
    max-height: 290px;
    height: 290px;
    min-height: 290px;
  }
  .demo-text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 430px) {
  .popup-container {
    min-height: 650px;
    max-height: 650px;
    height:  650px;
  }

  .next-previous-buttons {
    font-size: 30px;
  }

  .demo-text {
    height: 120px;
    font-size: 18px;
  }

  .slide-image-container {
    margin-top: 10px;
    max-height: 390px;
    height: 390px;
    min-height: 390px;
    align-content: space-between;
  }

  .slide-image-container.has-demo-description {
    margin-top: 10px;
    max-height: 260px;
    height: 260px;
    min-height: 260px;
    align-content: space-between;
  }

  .slide-container img {
    width: 70%;
    margin-bottom: 0;
  }
}

</style>
